import api from './index';
import { IWorkshop, IWorkshopPaginate } from '../types/workshopInterface';

interface AxiosError {
  error: string;
}

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
};

export const getWorkshops = async (
  token: string,
  props: {
    page?: number;
    limit?: number;
    searchText?: string;
  }
) => {
  const { page, limit, searchText } = props;
  const response = await api.get<IWorkshopPaginate | AxiosError>('/workshop', {
    headers: {
      ...commonHeaders,
      Authorization: `Bearer ${token}`,
    },
    params: {
      page,
      limit,
      searchText,
    },
  });
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IWorkshopPaginate;
};

export const getWorkshop = async (workshopId: string, token: string) => {
  const response = await api.get<IWorkshop | AxiosError>(
    `/workshop/${workshopId}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IWorkshop;
};

export const createWorkshop = async (
  workshop: Partial<IWorkshop>,
  token: string
) => {
  const response = await api.post<IWorkshop | AxiosError>(
    '/workshop',
    workshop,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IWorkshop;
};

export const updateWorkshop = async (
  workshopId: string,
  workshop: Partial<IWorkshop>,
  token: string
) => {
  const response = await api.put<IWorkshop | AxiosError>(
    `/workshop/${workshopId}`,
    workshop,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IWorkshop;
};

export const deleteWorkshop = async (workshopId: string, token: string) => {
  const response = await api.delete<IWorkshop | AxiosError>(
    `/workshop/${workshopId}`,
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IWorkshop;
};

export const searchWorkshops = async (
  token: string,
  props: {
    page?: number;
    limit?: number;
  },
  body: Partial<IWorkshop>
) => {
  const { page, limit } = props;
  const response = await api.post<IWorkshopPaginate | AxiosError>(
    '/workshop/search',
    { ...body },
    {
      headers: {
        ...commonHeaders,
        Authorization: `Bearer ${token}`,
      },
      params: {
        page,
        limit,
      },
    }
  );
  if (response.status !== 200) {
    throw new Error((response.data as AxiosError).error as string);
  }
  return response.data as IWorkshopPaginate;
};
