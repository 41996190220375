import React, { useEffect, useState } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import HeaderShared from '../../../../shared/Header/header.shared';
import ProcessStatusComponent from '../../../../components/ProcessStatus/processStatus.component';
import FooterShared from '../../../../shared/Header/footer.shared';
import TallerSelectionComponent from '../../../../components/Modals/TallerModal/tallerModal.component';
import PrivacyComponent from '../../../../components/Banners/Privacy/privacy.component';
import { getCurrentAuthenticatedUser } from '../../../../utils/utils';
import LoaderComponent from '../../../../components/Loader/loader.component';
import { IUser } from '../../../../types/userInterface';
import {
  getWorkshop,
  searchWorkshops,
  updateWorkshop,
} from '../../../../api/workshopApi';
import { IWorkshop } from '../../../../types/workshopInterface';
import TalleresComponent from '../../../../components/Elements/Talleres/taller.component';
import { updateUser } from '../../../../api/usersApi';

const TalleresScreen: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Partial<IUser>>({});
  const [token, setToken] = useState<string>('');
  const [workshops, setWorkshops] = useState<IWorkshop[]>([]);
  const [workshopSelected, setWorkshopSelected] = useState<Partial<IWorkshop>>(
    {}
  );
  const [modalShow, setModalShow] = useState(false);
  const steps = [
    {
      title: 'Cuéntanos de ti',
      description: 'Perfil completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'Completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te invitamos al taller',
      description: 'Revisa catálogo',
      status: 'status--process',
      completed: false,
      color: 'purple500',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
  ];

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  const fetchPossibleWorkshops = async (
    workshopsIds: string[],
    token: string
  ) => {
    try {
      const workshops = await searchWorkshops(
        token,
        {
          page: 1,
          limit: 100,
        },
        { ids: workshopsIds }
      );
      setWorkshops(workshops.docs);
    } catch (error) {
      setError('Error al cargar los talleres');
    }
  };

  const signUpWorkshop = async (workshopId: string) => {
    try {
      setLoading(true);
      const response = await updateUser(
        user._id || '',
        {
          workshopSelected: {
            workshopId,
            createdAt: new Date().toISOString(),
          },
        },
        token
      );
      const currentWorkshop = await getWorkshop(workshopId, token);
      const assignedUsers = currentWorkshop.assignedUsers || [];
      await updateWorkshop(
        workshopId,
        {
          assignedUsers: [
            ...assignedUsers,
            {
              userId: user._id || '',
              status: 'accepted',
              createdAt: new Date().toISOString(),
            },
          ],
        },
        token
      );
      if (response) {
        navigate(`/chat-grupal/${workshopId}`);
      }
    } catch (error) {
      setError('Error al inscribirse al taller');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (currentUser) {
          const { amigasUser } = currentUser;
          setUser(amigasUser);
          setToken(currentUser.jwtToken);
          if (amigasUser.possibleWorkshops) {
            if (amigasUser.workshopSelected) {
              return navigate(
                `/chat-grupal/${amigasUser.workshopSelected.workshopId}`
              );
            }
            await fetchPossibleWorkshops(
              amigasUser.possibleWorkshops,
              currentUser.jwtToken
            );
          }
        }
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <TallerSelectionComponent
        show={modalShow}
        onHide={() => setModalShow(false)}
        workshopSelected={workshopSelected}
        confirmSignUp={signUpWorkshop}
      />

      <HeaderShared />
      <section className="module container">
        <div className="body-row row">
          {loading && <LoaderComponent />}
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom40 row">
              <div className="col-12">
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12 text-center">
                <h3 className="h1">Taller</h3>
                <p className="text-20 module-bottom40">
                  Hola soy tu tallerista.
                </p>
                <p>
                  Regístrate en uno de los talleres disponibles, recuerda que es
                  únicamente para mujeres.{' '}
                </p>
              </div>
            </div>
            <div className="row">
              {workshops.map((workshop, key) => (
                <TalleresComponent
                  key={key}
                  showAvailableSlotsBadge={true}
                  showStockList={true}
                  cols={'col-xl-3'}
                  workshop={workshop}
                  user={user}
                  setModalShow={(value, workshop) => {
                    setWorkshopSelected(workshop);
                    setModalShow(value);
                  }}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default TalleresScreen;
