import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { IWorkshop } from '../../../types/workshopInterface';
import TalleresComponent from '../../Elements/Talleres/taller.component';

interface TallerSelectionProps {
  show: boolean;
  onHide: () => void;
  workshopSelected: Partial<IWorkshop>;
  confirmSignUp: (workshopId: string) => void;
}

const TallerSelectionComponent: React.FC<TallerSelectionProps> = ({
  show,
  onHide,
  workshopSelected,
  confirmSignUp,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header className="justify-content-center">
        <Modal.Title id="contained-modal-title-vcenter" className="text-center">
          <h2>Confirma tu asistencia al taller</h2>
          <p className="text-24">Revisa los datos para tu participación</p>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="modal-selection__row row">
          <TalleresComponent
            showAvailableSlotsBadge={true}
            showStockList={true}
            cols={'col-lg-6'}
            workshop={workshopSelected}
            showSignUpButton={false}
          />
          <aside className="modal-selection__right col-lg-6 col-12 text-center">
            <h4 className="text-regular text-500 text-black">
              Siguientes pasos:
            </h4>
            <ul className="center-list">
              <li>
                <i className="icon icon--conversation"></i>
                <p>
                  Te enviaremos todos los detalles{' '}
                  <strong>al Chat Grupal</strong>
                </p>
              </li>
              <li>
                <i className="icon icon--love"></i>
                <p>
                  Si tienes dudas, <strong>contacta a tu acompañante</strong>
                </p>
              </li>
            </ul>
          </aside>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="col-12 text-center mb-4">
          <a
            href="#"
            className="btn btn--type1"
            onClick={() => confirmSignUp(workshopSelected._id || '')}
          >
            Confirmar e ir al Chat Grupal
          </a>
        </div>
        <div className="col-12 text-center mb-4">
          <button type="button" onClick={onHide} className="btn--type3">
            Elegir otro taller en otra fecha
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default TallerSelectionComponent;
