import React, { useState, useEffect } from 'react';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import HeaderShared from '../../../shared/Header/header.shared';
import ProcessStatusComponent from '../../../components/ProcessStatus/processStatus.component';
import DonateComponent from '../../../components/Banners/Donate/donate.component';
import FooterShared from '../../../shared/Header/footer.shared';
import Step3Component from '../../../components/Process/Step3/step3.component';
import PrivacyComponent from '../../../components/Banners/Privacy/privacy.component';
import LoaderComponent from '../../../components/Loader/loader.component';
import { IUser } from '../../../types/userInterface';
import { getCurrentAuthenticatedUser } from '../../../utils/utils';

const TallerScreen: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<Partial<IUser>>({});
  const steps = [
    {
      title: 'Cuéntanos de ti',
      description: 'Perfil completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te presentamos a tu acompañante',
      description: 'Completado',
      status: 'status--success',
      completed: true,
      color: 'green300',
    },
    {
      title: 'Te invitamos al taller',
      description: 'Revisa catálogo',
      status: 'status--process',
      completed: false,
      color: 'purple500',
    },
    {
      title: 'Seguimos en contacto',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
    {
      title: 'Déjanos tu opinión',
      description: 'Pendiente',
      status: '',
      completed: false,
      color: 'gray600',
    },
  ];

  const setError = (message: string) => {
    console.log(message);
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: message,
    });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentUser = await getCurrentAuthenticatedUser();
        if (currentUser) {
          setUser(currentUser.amigasUser);
          if (currentUser.amigasUser.workshopSelected?.workshopId) {
            navigate(
              `/chat-grupal/${currentUser.amigasUser.workshopSelected?.workshopId}`
            );
          }
        }
      } catch (error) {
        setError('Error al cargar la información');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  return (
    <>
      <HeaderShared user={user} />
      <section className="module container">
        {loading && <LoaderComponent />}
        <div className="body-row row">
          <div className="col-12">
            <div className="module-bottom row">
              <div className="col-12">
                <PrivacyComponent />
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h1 className="text-64 mb-3">Hola, {user.username || ''}</h1>
                <p className="text-32 mb-0">
                  <strong>No. {user.userId || ''}</strong>
                </p>
              </div>
            </div>
            <div className="module-bottom row">
              <div className="col-12">
                <h2 className="h1">Tu proceso</h2>
                <ProcessStatusComponent steps={steps} currentStepIndex={0} />
              </div>
              <Step3Component />
            </div>
            <div className="row">
              <div className="col-12">
                <DonateComponent />
              </div>
            </div>
          </div>
        </div>
      </section>
      <FooterShared />
    </>
  );
};

export default TallerScreen;
