import React from 'react';
import { IWorkshop } from '../../../types/workshopInterface';
import { IUser } from '../../../types/userInterface';

interface TalleresComponentProps {
  showAvailableSlotsBadge?: boolean;
  showStockList?: boolean;
  cols?: string;
  workshop: Partial<IWorkshop>;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  setModalShow?: (
    value: React.SetStateAction<boolean>,
    workshop: Partial<IWorkshop>
  ) => void;
  user?: Partial<IUser>;
  showSignUpButton?: boolean;
}

const TalleresComponent: React.FC<TalleresComponentProps> = ({
  showAvailableSlotsBadge = true,
  showStockList = true,
  cols = 'col-md-4',
  workshop,
  setModalShow,
  showSignUpButton = true,
}) => {
  const getBadgeClass = (cupoDisponible: number, cupoTotal: number) => {
    if (cupoDisponible === cupoTotal) {
      return 'badge--blue';
    } else if (cupoDisponible <= cupoTotal * 0.25) {
      return 'badge--danger';
    } else {
      return 'badge--info';
    }
  };

  const getWorkshopTypeBadge = (workshopType: string) => {
    switch (workshopType) {
      case 'En línea':
        return 'badge--success';
      case 'Presencial':
        return 'badge--info';
      default:
        return 'badge--primary';
    }
  };

  const getStockList = () => {
    const stockList = [1, 2, 3, 4, 5];
    const takedPlaces = workshop.assignedUsers?.length || 0;
    const workshopSize = workshop.availablePlaces || 0;
    const stock = workshopSize / stockList.length;
    return stockList.map((item, i) => {
      const isTaked = takedPlaces >= item * stock;
      return (
        <li key={i}>
          <span
            className={`icon material-icons-outlined ${
              isTaked ? 'stock--enabled' : 'stock--disabled'
            }`}
          >
            airline_seat_recline_normal
          </span>
        </li>
      );
    });
  };

  return (
    <>
      <div
        key={workshop.workshopId}
        className={`item-workshop__col ${cols} col-lg-4 col-sm-6 col-12 proximos`}
      >
        <div className={`item-workshop`}>
          <figure className="item-workshop__image">
            <p
              className={`badge badge-label ${getWorkshopTypeBadge(
                workshop.workshopType || ''
              )}`}
            >
              <span className="icon material-icons-outlined">verified</span>
              <span>
                <small>{(workshop.workshopType || '').toUpperCase()}</small>
              </span>
            </p>
            <img src="/assets/images/illustrations/remember.png" alt="Taller" />
          </figure>
          <article>
            {showAvailableSlotsBadge && (
              <p
                className={`badge badge-label ${getBadgeClass(
                  (workshop.availablePlaces || 0) -
                    (workshop.assignedUsers || []).length,
                  workshop.availablePlaces || 0
                )}`}
              >
                <span className="icon material-icons-outlined">
                  airline_seat_recline_normal
                </span>
                <span>{workshop.availablePlaces || '0'} CUPOS DISPONIBLES</span>
              </p>
            )}
            {showStockList && (
              <ul className="stock-list">
                {getStockList()}
                <li>
                  {(workshop.availablePlaces || 0) -
                    (workshop.assignedUsers || []).length}{' '}
                  CUPOS
                </li>
              </ul>
            )}
            <p className="item-workshop__date text-gray600 text-icon mb-0">
              <i className="icon icon--calendar-line"></i>
              {workshop.name} - {workshop.workshopTextDate}
            </p>
            <p className="text-icon">
              <i className="icon icon--clock"></i> {workshop.workshopSchedule}
            </p>
            {showSignUpButton && (
              <button
                type="button"
                onClick={() => setModalShow && setModalShow(true, workshop)}
                className="btn btn--type1"
              >
                Regístrate
              </button>
            )}
          </article>
        </div>
      </div>
    </>
  );
};

export default TalleresComponent;
